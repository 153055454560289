import React, { useState, useContext, useEffect} from 'react'
import { StateContext } from '../../../state';
import {  Row, Col, Button, Badge, Modal, Tooltip } from 'antd'
import './device.less'
import { Local, SignalTower, BoltOne, BatteryWorking ,BatteryFull,BatteryEmpty} from '@icon-park/react'
import tempOnline from '../../../../../../public/assets/tempOnline.png'
import tempUnline from '../../../../../../public/assets/tempUnline.png'
import humiOnline from '../../../../../../public/assets/humiOnline.png'
import humiUnline from '../../../../../../public/assets/humiUnline.png'
import paUnline from '../../../../../../public/assets/paUnline.png'
import paOnline from '../../../../../../public/assets/paOnline.png'
import signalOnline from '../../../../../../public/assets/signalOnline.png'
import signalUnline from '../../../../../../public/assets/signalUnline.png'
import signalTimeOut from '../../../../../../public/assets/timeout.png'
import signalTimeWarn from '../../../../../../public/assets/timeWarn.png'
import smoke from '../../../../../../public/assets/Smoke.png'
import smokeWarn from '../../../../../../public/assets/SmokeWarn.png'
import DeviceSet from './set'
import DeviceRoomSet from './roomset'
import DevicePaSet from './set205'
import DeviceSmokerSet from './set207'
import { Mode } from '../../../../../App';
import Draggable from 'react-draggable';
import DeviceHistory from './deviceHistory';
import DeviceMap from './deviceMap';
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

function DeviceItem(props) {
    const { state, dispatch } = useContext(StateContext)
    const [showHis, setShowHis] = useState(false)
    const [showHisMore, setShowHisMore] = useState(false)
    const [showSet, setShowSet] = useState(false)
    const [showLocation, setShowLocation] = useState(false)
    //历史数据

    const [startTime, setStartTime] = useState('')//历史数据开始时间
    const [startDTime, setStartDTime] = useState('')//当天00:00:00
    const [endTime, setEndTime] = useState('')

    //轨迹回放

    const [startMapTime, setStartMapTime] = useState('');//轨迹数据的开始时间
    const [endMapTime, setEndMapTime] = useState('');//轨迹数据的结束时间

    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();//移动对话框
    const [showUnit,setShowUnit] = useState('℃');
    const [showLastTime, setShowLastTime] = useState(false);
    let timerout =0;
    // 拖动方法
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const changeSetState = (num)=>{
        if(num>0 && state.main.type > 101 ){
            clearTimeout(timerout)
            timerout=setTimeout(() => {
                dispatch({ type: 'setState', setState: 0 });
                dispatch({ type: 'super', super: 0 });
            }, 1000*60*10);
        }
    }
    useEffect(()=>{
        let type = state.main.type;
        let showLastTime = false;
        if (window.CefSharp !== undefined) {
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            console.log('myConf',myConf);
            if(type<102){
                if (myConf.showTimeAdmin){
                    showLastTime = true
                }
            }
            if(type===103){
                if (myConf.showTimeManger){
                    showLastTime = true
                }
            }
            if(type===104){
                if (myConf.showTimeUser){
                    showLastTime = true
                }
            }
        }else{
            if(type<102){
                showLastTime = true
            }
        }
        setShowLastTime(showLastTime)
    },[])
    useEffect(() => {
        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
        setStartDTime(now_date);
        setShowUnit(props.item.Type===205?'Pa':props.item.Type===210?'MPa':'℃');
        // props.item.Type===205?setShowUnit('Pa'):setShowUnit('℃')
        // changeSetState(1);
        
    }, [props])

    return (
        <>
        {/* 历史数据 */}
            <Modal maskClosable={false} title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                >
                    历史数据
                </div>
                } width={'85vw'} 
                centered visible={showHis} destroyOnClose={true} 
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}
                onCancel={() => {
                    setShowHis(false)
                    setShowHisMore(false);
                    props.callback(false)
                    setStartTime(formatDateTimes(startDTime.getTime()));
                    setEndTime('');
                }}
            >
                <DeviceHistory item={props.item} start={startTime} end ={endTime} showMore = {showHisMore}></DeviceHistory>
 
            </Modal>
            <Modal maskClosable={false} title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        paddingRight:20
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                >
                    <div>
                        参数设置
                    </div>
                    <div>
                        <span>{Mode!=='soc'?null:' 用户: ' + state.district.filter(item => item.id === props.item.District)[0].user_name}</span>
                    </div>
                    <div>
                        <span>{' 区域: ' + state.district.filter(item => item.id === props.item.District)[0].name}</span>
                    </div>
                </div>
            }

            modalRender={modal => (
                <Draggable
                disabled={modalMoveDisabled}
                bounds={modalBounds}
                onStart={onStart}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            width={'85vw'} centered visible={showSet} footer={null} destroyOnClose={true}
                onCancel={() => {
                    setShowSet(false)
                    props.callback(false)
                }}
            >
                 {/* {props.item.Type <204 && <DeviceSet item={props.item} cloud={props.cloud} />} */}
                 {props.item.Type === 204 ? <DeviceRoomSet item={props.item} cloud={props.cloud}  changeSetState = {changeSetState}/>:
                 (props.item.Type === 205||props.item.Type === 210) ? <DevicePaSet item={props.item} cloud={props.cloud}  changeSetState = {changeSetState}/>:
                 props.item.Type === 207 ? <DeviceSmokerSet item={props.item} cloud={props.cloud}  changeSetState = {changeSetState}/>:
                 <DeviceSet item={props.item} cloud={props.cloud} changeSetState = {changeSetState} />
                 }
                {/* {
                    props.item.Online?
                    <DeviceSet item={props.item} cloud={props.cloud} />:<div style={{fontSize:20,paddingLeft:400}}>设备离线</div>
                } */}
            </Modal>
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    轨迹回放
                    </div>
                } width={'100%'} centered visible={showLocation} destroyOnClose footer={null}
                modalRender={modal => (
                    <Draggable
                    disabled={modalMoveDisabled}
                    bounds={modalBounds}
                    onStart={onStart}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                onCancel={() => {setShowLocation(false)}}>
                <DeviceMap item ={props.item} start={startMapTime} end ={endMapTime}></DeviceMap>
            </Modal>
            {props.show === 1 ?
                //列表布局 
                <div className={'rowItem'} style={{ borderLeft: props.cloud ? '1px solid rgb(11, 112, 143)' : '',backgroundColor:'#fff' }} 
                onDoubleClick={() =>{
                    setShowHisMore(true); 
                    startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                    endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                    setShowHis(true);
                }}>
                    <Row align='middle' >
                        {/* 测点名称 */}
                        <Tooltip title={props.item.MName}>
                        <Col xxl={5} xl={4} style={{display:'flex',alignItems:'center'}}>
                            {/* 设备到期状态 */}
                            <div style={{ width: 30 }}>
                            {
                                props.item.ExprieStatus === 0 ? ''
                                :props.item.ExprieStatus === 3 ? <img style={{ width: 20, height: 20 }}src={ signalTimeOut } alt="" />
                                :<img style={{ width: 20, height: 20 }}src={ signalTimeWarn } alt="" />
                                // <AlertOutlined  style={{color:'brown',fontSize:16}}/>
                            }
                            </div>
                            {
                                props.item.Node===0?
                                <div>
                                    <div style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>{props.item.MName}</div>
                                    <div style={{  fontSize: 12, color: '#000' }}>{'设备状态'}</div>
                                </div>:
                                <div >
                                    <div style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>{props.item.Name}</div>
                                    <div style={{ display: 'flex', fontSize: 12, color: '#000' }}>
                                        <div style={{width:100 ,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                            {props.item.MName}
                                        </div>
                                        <div>{" ID:" +  props.item.Sn+'-'+props.item.Node}</div>
                                    </div>
                                </div>
                            }
                        </Col>
                        </Tooltip>
                        {/* 设备状态 是否在线 */}
                        <Col xxl={1} xl={2}>
                            <span className={'devicestate'}><Badge  text={props.item.Online ?props.item.Node===0?"在线":props.item.Sensor.HumiValue===102.3||props.item.Sensor.TempValue === 102.3 ? '未连接' : props.item.Sensor.HumiValue === 102.2||props.item.Sensor.TempValue === 102.2 ? '正在连接' : !props.item.Sensor.TempSwitch?'停用':"在线" : "离线"} status={props.item.Online ? "success" : "default"} /></span>
                        </Col>
                        {/* 设备温湿度值  开关门 电源状态 */}
                        <Col xxl={8} xl={8}>
                        {
                            // 开关门
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm===0&&props.item.Sensor.DoorAlarm===1)?
                            <div style={{ display: 'flex',fontSize:16,fontWeight:'bold',justifyContent:'center' }}>
                                {
                                    props.item.Online ?
                                    props.item.Status.Door===1  ?
                                    <span style={{color:'red'}}> 开 门 </span>:
                                    <span> 关 门 </span>:
                                    '--------------'
                                }
                            </div>:
                            //电源状态
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm>0&&props.item.Sensor.DoorAlarm===0)?
                            <div style={{ display: 'flex',fontSize:16,fontWeight:'bold' ,justifyContent:'center'}}>
                                {
                                    props.item.Online ?
                                    props.item.Status.PowerStatus===1 ?
                                    <span style={{color:'red'}}> 断 电 </span>:
                                    <span > 电 源 正 常 </span>:
                                    '--------------'
                                }
                            </div>:
                            // 开关门 电源状态同时
                            (props.item.Node===0&&props.item.Sensor.PowerAlarm>0&&props.item.Sensor.DoorAlarm===1)?
                            <div style={{fontSize:16,fontWeight:'bold'}}>
                                <div style={{fontWeight:'normal',fontSize:10,display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
                                    <span style={{display:'inline-block'}}>电源状态</span>
                                    <span style={{display:'inline-block'}}>门状态</span>
                                </div>
                                {
                                    props.item.Online ?
                                    <div style={{ display: 'flex',justifyContent:'space-evenly'}}>
                                        <div >
                                            {
                                                props.item.Status.PowerStatus===1 ?
                                                <span style={{color:'red'}}> 断 电 </span>:
                                                <span > 正 常 </span>
                                            }
                                        </div>
                                        <div >
                                            {
                                                props.item.Status.Door===1  ?
                                                <span style={{color:'red'}}> 开 门 </span>:
                                                <span > 关 门  </span>
                                            }
                                        </div>
                                    </div>:
                                    <div style={{ display: 'flex',justifyContent:'space-evenly'}}>
                                        <div ><span style={{color:'#ccc'}}>--------------</span></div>
                                        <div><span style={{color:'#ccc'}}>--------------</span></div>
                                    </div>
                                }
                            </div>:
                            // 正常温湿度数据
                            <div style={{ display: 'flex',justifyContent:'center'}}>
                                <div style={{width:60}}>
                                    <div>
                                        <span>上限:{props.item.Sensor.TempAlarmHigh}</span>
                                    </div>
                                    <div>
                                        <span>下限:{props.item.Sensor.TempAlarmLow}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex',alignItems:'center' ,width:100}}>
                                    <img width={32} src={
                                        props.item.Online ? 
                                        props.item.Type===205?paOnline:tempOnline 
                                        : props.item.Type===205?paUnline:tempUnline} />
                                    {/* <div style={{ fontSize: 18, color: !props.item.Sensor.TempSwitch?'black':erjinzhi(props.item.Sensor.AlarmStatus).slice(2, 3) === '1' ? 'red' : 'black' }}> */}
                                    <div style={{ fontSize: 18, color: !props.item.Sensor.TempSwitch?'black':props.item.Sensor.TempAlarm? 'red' :props.item.Sensor.TempWarning?'orange':'black' }}>
                                        {
                                            props.item.Sensor.TempValue === 102.3 || props.item.Sensor.TempValue === 102.2||!props.item.Sensor.TempSwitch ? '--' :
                                            props.item.Sensor.TempValue + showUnit
                                        }
                                    </div>
                                </div>
                                {
                                    props.item.Humi === 1 ?
                                        <div style={{display: 'flex'}}>
                                            <div style={{ display: 'flex',width:120,alignItems:'center'}}>
                                                <img width={32} src={props.item.Online ? humiOnline : humiUnline} />
                                                {/* <div style={{ fontSize: 18, color:!props.item.Humi?'black':erjinzhi(props.item.Sensor.AlarmStatus).slice(1, 2) === '1' ? 'red' : 'black' }}> */}
                                                <div style={{ fontSize: 18, color:props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange':'black'}}>
                                                    {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 ||!props.item.Sensor.HumiSwitch? '--' : props.item.Sensor.HumiValue + "%RH"}
                                                </div>
                                            </div> 
                                            <div style={{width:60}}>
                                                <div>
                                                    <span>上限:{props.item.Sensor.HumiAlarmHigh}</span>
                                                </div>
                                                <div>
                                                    <span>下限:{props.item.Sensor.HumiAlarmLow}</span>
                                                </div>
                                            </div>
                                        </div>: null
                                }
                            </div>
                        }
                        </Col>
                        {/* 信号电源电量 */}
                        <Col xxl={3} xl={3}>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                {/* 定位状态 */}
                                {
                                    props.item.Type === 203 ? //车载GPS定位
                                    <div className='gomap' style={{ width: 70, display: 'flex', alignItems: 'center', }} onClick={() => {
                                        startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                        endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                        setShowLocation(true);
                                        }}>
                                        <Local theme="two-tone" size="24" fill={props.item.Online && props.item.Status.GPSStatus ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                        <span style={{ fontSize: 14 }}>GPS</span>
                                    </div> : 
                                    props.item.Type === 202 ?//保温箱LBS定位
                                    <div className='gomap' style={{ width: 70, display: 'flex', alignItems: 'center', }} onClick={() => {
                                        startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                        endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                        setShowLocation(true);
                                    }}>
                                        <SignalTower theme="two-tone" size="24" fill={props.item.Online ?props.item.Status.BaseLac!==0? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']: ['#ccc', '#ddd']} />
                                        <span style={{ fontSize: 14}}>LBS</span>
                                    </div> : <div style={{ width: 70 }}></div>
                                }
                                {/* 信号值 */}
                                <div style={{ width: 60, display: 'flex', alignItems: 'center', }}>
                                    <img style={{ width: 24, height: 24, marginBottom: 5 }} src={props.item.Online && props.item.Status.Csq > 0 ? signalOnline : signalUnline} alt="" />
                                    <span style={{ fontSize: 14 }}>{props.item.Online && props.item.Status.Csq > 0 ? props.item.Status.Csq : ' '}</span>
                                </div>
                                {/* 电量状态 值 */}
                                <div style={{ width: 60, display: 'flex', alignItems: 'center', }}>
                                    {
                                        props.item.Status.PowerStatus === 0 ?
                                            <BoltOne theme="two-tone" size="24" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                            <BatteryWorking theme="two-tone" size="24" fill={props.item.Online ?  ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                    }
                                    <span style={{ fontSize: 14 }}>{props.item.Online ? props.item.Status.BatPower + '%' : ''}</span>
                                </div>
                            </div>
                        </Col>
                        {/* 时间 */}
                        {
                            showLastTime&&
                            <Col xxl={4} xl={3} style={{color: '#666', fontSize: 12,textAlign:'center'}}>
                            <div>更新时间：</div>
                            <div>{props.item.Status.LastMsgTime}</div>
                            </Col>
                        }
                        {/* 轨迹回放按钮 */}
                        <Col xxl={3} xl={4}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <Button style={{ marginRight: 5,borderRadius:10 }} 
                                onClick={() => {
                                    (props.item.ExprieStatus !== 3||state.main.type<102 )&& setShowSet(true)
                                }} 
                                disabled={props.item.ExprieStatus === 3 && state.main.type>101}>
                                   参数设置
                                </Button>
                                <Button style={{ marginRight: 5,borderRadius:10 }} disabled={props.item.ExprieStatus === 3&&state.main.type>101 } onClick={() => {
                                startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                setShowHis(true)
                                props.callback(true)
                                }}>历史数据</Button>  
                            </div>
                        </Col>
                    </Row>
                </div> :
            props.show === 0 ?
                //图表布局
                <div style={{  marginBottom: 8, alignItems: 'center' }}  
                >
                   
                    <div style={{ borderLeft: props.cloud ? '1px solid rgb(11, 112, 143)' : '1px solid #ccc',position:'relative'}} className='chartLay'>                       
                        <div className='smokerAlarm' style={{visibility:(props.item.Type===207&&props.item.Sensor.Smoke)?'visible':'hidden'}}></div>
                        <div >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Tooltip title={props.item.Node===0?props.item.MName:props.item.Name}>
                                <div style={{ fontSize: '11px', fontWeight: 'bold', flex: 1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                                    <span className={'devicestate'}>
                                        {
                                            props.item.ExprieStatus === 0 ? <Badge status={props.item.Online ? "success" : "default"} />
                                            :props.item.ExprieStatus === 3 ? <img style={{ width: 20, height: 20 }}src={ signalTimeOut } alt="" />
                                            :<img style={{ width: 20, height: 20 }}src={ signalTimeWarn } alt="" />
                                            // <AlertOutlined  style={{color:'brown',fontSize:16}}/>
                                        }
                                    </span>
                                    <span>{props.item.Node===0?props.item.MName:props.item.Name}</span>
                                </div>
                                </Tooltip>
                                <div style={{width:55,fontSize:10}}>
                                    {
                                        props.item.Node===0?'':!props.item.Online?'':!props.item.Sensor.TempSwitch ? '停用': props.item.Sensor.TempValue === 102.3 ? '未连接' : props.item.Sensor.TempValue === 102.2 ? '正在连接' :props.item.Sensor.Hide===2||props.item.Sensor.Hide===3?'空库' :''
                                    }
                                </div>

                            </div>
                            {/* 状态 测点ID 信号状态 */}
                            <div style={{ display: 'flex', fontSize: '12px', color: '#666666' ,justifyContent:'space-between'}}>
                                <div style={{flex: 1 }}> 
                                    <span style={{ display: 'inline-block',paddingLeft:5 }}>{props.item.Node===0?'设备状态':"ID:" +props.item.Sn+'-'+ props.item.Node}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: 0,width:90,}}>
                                    
                                    {
                                        //203 车载设备  202保温箱   201  监测主机
                                        (props.item.Type === 203 || props.item.Type === 201) && props.item.Location == 0   ?
                                        <Tooltip title={props.item.Online && props.item.Status.GPSStatus  ? 'GPS定位' : 'GPS未定位'}>
                                            <Local className='gomap' theme="two-tone" size="20" fill={props.item.Online && props.item.Status.GPSStatus ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} onClick={() => {
                                                startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                                endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                                setShowLocation(true);
                                                }} />
                                        </Tooltip> :
                                        props.item.Type === 202 ?
                                        <Tooltip title={props.item.Location === 1 && props.item.Online ? 'LBS定位' : ''}>
                                            <SignalTower className='gomap' theme="two-tone" size="20" fill={props.item.Location === 1 && props.item.Online ? props.item.Status.BaseLac!==0?['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']: ['#ccc', '#ddd']} onClick={() => {
                                                startMapTime===''&& setStartMapTime(formatDateTimes(startDTime.getTime()));
                                                endMapTime===''&& setEndMapTime(formatDateTimes(new Date().getTime()));
                                                setShowLocation(true);
                                                }} />
                                        </Tooltip> : 
                                        props.item.Type === 207 && props.item.Node!==0?
                                        <Tooltip title={props.item.Sensor.Smoke ? '烟感报警' : '烟感正常'}>
                                            {
                                                props.item.Sensor.Smoke ?
                                                
                                                <img style={{ width: 20, height: 20 }}src={ smokeWarn } alt="" />
                                                :
                                                <img style={{ width: 20, height: 20 }}src={ smoke } alt="" />
                                            }
                                            
                                        </Tooltip> : 
                                        
                                        <div style={{width:30}}></div>
                                    }
                                    <Tooltip title={props.item.Status.Csq}>
                                        <img style={{ width: 20, height: 20 }} src={!props.item.Online ? signalUnline:props.item.Node===0?signalOnline:props.item.Sensor.TempValue<100 && props.item.Sensor.TempSwitch  ? signalOnline : signalUnline} alt="" />
                                        {/* <Signal theme="outline" size="20" fill={props.item.Online && props.item.Sensor.TempValue<100 ?'#48c7e9':'#ccc' } strokeLinejoin="bevel"/> */}
                                    </Tooltip>
                                    {
                                        (props.item.Type === 208 && props.item.Node!==0 )?
                                        <Tooltip title={props.item.Online ?  props.item.Sensor.BatPower + '%' : ''}>
                                            {
                                                props.item.Sensor.PowerStatus === 1 ?
                                                    <BoltOne theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                                (!props.item.Sensor.TempSwitch || props.item.Sensor.TempValue>100)?
                                                    <BatteryFull theme="outline" size="20" fill='#ccc'/>:
                                                props.item.Sensor.BatPower>80?
                                                    <BatteryFull theme="outline" size="20" fill={props.item.Online ? '#48c7e9': '#ccc'}/>:
                                                props.item.Sensor.BatPower>30?
                                                    <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                                :props.item.Sensor.BatPower>20?
                                                    <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#f4ea2a', '#ffffff'] : ['#ccc', '#ddd']} />
                                                    :
                                                    <BatteryEmpty theme="outline" size="20" fill={(props.item.Online ) ? '#d81e06': '#ccc'}/>
                                                    // 
                                            }
                                        </Tooltip>
                                        :props.item.Node===0?
                                        <Tooltip title={props.item.Status.BatPower + '%' }>
                                            {
                                                props.item.Status.PowerStatus === 0 ?
                                                <BoltOne theme="two-tone" size="20" fill={props.item.Online? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                                // <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                                props.item.Status.BatPower>80?
                                                <BatteryFull theme="outline" size="20" fill={props.item.Online ? '#48c7e9': '#ccc'}/>
                                                :props.item.Status.BatPower>30?
                                                <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                                :props.item.Status.BatPower>10?
                                                <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#f4ea2a', '#ffffff'] : ['#ccc', '#ddd']} />
                                                :
                                                <BatteryEmpty theme="outline" size="20" fill={props.item.Online ? '#d81e06': '#ccc'}/>
                                            }
                                        </Tooltip>
                                        :
                                        <Tooltip title={ props.item.Status.BatPower + '%'}>
                                            {
                                                props.item.Status.PowerStatus === 0 ?
                                                    <BoltOne theme="two-tone" size="20" fill={props.item.Online && props.item.Sensor.TempSwitch && props.item.Sensor.TempValue < 100 ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} /> :
                                                    // <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                                (!props.item.Sensor.TempSwitch || props.item.Sensor.TempValue>100)?
                                                    <BatteryFull theme="outline" size="20" fill='#ccc'/>:
                                                    props.item.Status.BatPower>80?
                                                    <BatteryFull theme="outline" size="20" fill={props.item.Online ? '#48c7e9': '#ccc'}/>
                                                    :props.item.Status.BatPower>30?
                                                    <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#48c7e9', '#ffffff'] : ['#ccc', '#ddd']} />
                                                    :props.item.Status.BatPower>10?
                                                    <BatteryWorking theme="two-tone" size="20" fill={props.item.Online ? ['#f4ea2a', '#ffffff'] : ['#ccc', '#ddd']} />
                                                    :
                                                    <BatteryEmpty theme="outline" size="20" fill={props.item.Online ? '#d81e06': '#ccc'}/>
                                            }
                                        </Tooltip>
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #ccc', marginTop: '5px', alignItems: 'center' }}>

                            {
                                showLastTime?
                                <div style={{ flex: 1, color: '#666', fontSize: 12, paddingLeft: 10 }}>
                                    {"更新时间：" + props.item.Status.LastMsgTime}
                                </div>
                                :
                                <div style={{height:10}}>

                                </div>
                            }

                        </div>
                        {/* 不同类型设备的数据展示 */}
                        {
                            props.item.Node===0?
                            props.item.Type===207?//烟感设备测点0
                            <div >
                                {
                                    props.item.Sensor.DeHumiMode===0?
                                    <div style={{ display: 'flex',height:70,justifyContent:'space-between',alignItems:'center',padding:'0px 20px'}}>
                                        {/* <div style={{width:15,fontSize:12}}>上限{props.item.Sensor.DeHumiHigh}</div> */}
                                        <div style={{textAlign:'center'}} >
                                            <div>自动除湿</div>
                                            <div>{props.item.Sensor.DeHumiStatus===0?'关闭':'开启'}</div>
                                        </div>
                                        <div>
                                            <div >上限:{props.item.Sensor.DeHumiHigh}</div>
                                            <div >下限:{props.item.Sensor.DeHumiLow} </div>
                                        </div>
                                        {/* <div style={{width:15,fontSize:12}}>下限{props.item.Sensor.DeHumiLow}</div> */}
                                    </div>
                                    :
                                    <div style={{ display: 'flex',height:70,justifyContent:'space-between',alignItems:'center',textAlign:"center",padding:'0px 10px'}}>
                                        <div></div>
                                        <div style={{textAlign:"center"}}>
                                            <div>手动除湿</div>
                                            <div>{props.item.Sensor.DeHumiStatus===0?'关闭':'开启'}</div>
                                        </div>
                                        <div></div>
                                    </div>
                                }                                                              
                            </div>:
                            (props.item.Sensor.PowerAlarm===0&&props.item.Sensor.DoorAlarm===1)?//其他设备测点0 开关门
                            <div style={{ display: 'flex',fontWeight:'bold',fontSize:12,height:70,justifyContent:'center',alignItems:'center'}}>
                                {
                                    props.item.Online ? 
                                    props.item.Status.Door===1 ?
                                    <span style={{color:'red',}}> 开 门 </span>:
                                    <span > 关 门 </span>:
                                    <span style={{color:'#ccc'}}>--------------</span>
                                }
                            </div>:
                            (props.item.Sensor.PowerAlarm>0&&props.item.Sensor.DoorAlarm===0)?//其他设备测点0 电源状态
                            <div style={{ display: 'flex',fontWeight:'bold',fontSize:18,height:70,justifyContent:'center',alignItems:'center'}}>
                                {
                                    props.item.Online ?
                                    props.item.Status.PowerStatus===1 ?
                                    <span style={{color:'red'}}> 断 电 </span>:
                                    <span > 电 源 正 常 </span>:
                                    <span style={{color:'#ccc'}}>--------------</span>
                                }
                            </div>: //其他设备测点0 电源状态  开关门
                            <div style={{ display: 'flex',flexDirection:'column',fontSize:12,fontWeight:'bold',height:70,justifyContent:'center'}}>
                                <div style={{fontSize:10,fontWeight:'normal',display:'flex',justifyContent:'space-around'}}>
                                    <span>电源状态</span>
                                    <span>门状态</span>
                                </div>
                                {
                                    props.item.Online ?
                                    <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                        <div >
                                            {
                                                 props.item.Status.PowerStatus===1 ?
                                                <span style={{color:'red'}}> 断 电 </span>:
                                                <span >  正 常 </span>
                                            }
                                        </div>
                                        <div >
                                            {
                                                props.item.Status.Door===1  ?
                                                <span style={{color:'red'}}> 开 门  </span>:
                                                <span > 关 门 </span>
                                            }
                                        </div>
                                    </div>:
                                    <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                        <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                        <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                    </div>
                                }
                            </div>://非测点0的数据
                            <div> 
                                {/* 温湿度图标 数据 单位 */}
                                <div style={{ display: 'flex', marginTop: '10px',  justifyContent: 'space-around' }}>
                                    {/* 温度图标 数据 单位 */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img width={32} src={props.item.Online ? 
                                            props.item.Type===205?paOnline:tempOnline  
                                            : props.item.Type===205?paUnline: tempUnline} />
                                            <div style={{ fontSize: 18, color: !props.item.Sensor.TempSwitch?'black':props.item.Sensor.TempAlarm? 'red' :props.item.Sensor.TempWarning?'orange':'black' }}>
                                                {props.item.Sensor.TempValue === 102.3 || props.item.Sensor.TempValue === 102.2 || !props.item.Sensor.TempSwitch||(props.item.ExprieStatus === 3&& state.main.type>101) ? '--' : props.item.Sensor.TempValue + showUnit}
                                            </div>
                                    </div>
                                    {/* 湿度图标 数据 单位 */}
                                    {
                                        props.item.Humi === 1 ?
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img width={32} src={props.item.Online ? humiOnline : humiUnline} />
                                                <div style={{ fontSize: 18, color:props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange' :'black'}}>
                                                    {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 || !props.item.Sensor.HumiSwitch ||(props.item.ExprieStatus === 3&& state.main.type>101) ?  '--' : props.item.Sensor.HumiValue + "%RH"}
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                {
                                    state.main.type>101&&<div style={{height:5}}></div>
                                }
                                <div style={{display: 'flex',justifyContent: 'space-around',marginBottom:6}}>
                                    <div>
                                        {
                                            props.item.Type===207?//烟感设备
                                            <span style={{fontSize:10,color: '#666'}}>
                                                上下限:{props.item.Sensor.TempWarningHigh}~{props.item.Sensor.TempWarningLow}
                                            </span>
                                            :
                                            <span style={{fontSize:10,color: '#666'}}>
                                                上下限:{props.item.Sensor.TempAlarmHigh}~{props.item.Sensor.TempAlarmLow}
                                            </span>
                                        }
                                        
                                    </div>
                                    {
                                         props.item.Humi === 1 &&
                                         <div>
                                            {
                                                props.item.Type===207?
                                                <span style={{fontSize:10,color: '#666'}}>
                                                    上下限:{props.item.Sensor.HumiWarningHigh}~{props.item.Sensor.HumiWarningLow}
                                                </span>
                                                :
                                                <span style={{fontSize:10,color: '#666'}}>
                                                    上下限:{props.item.Sensor.HumiAlarmHigh}~{props.item.Sensor.HumiAlarmLow}
                                                </span>
                                            }
                                            
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tooltip title={props.item.MName}>
                                {/* <div style={{width:90,borderRadius:10,border:'1px solid #ccc',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',textAlign:'center',paddingTop:3,paddingLeft:1}}>
                                    {props.item.MName}
                                </div> */}
                                <Button style={{borderRadius:10,paddingLeft:5,paddingRight:5}}
                                    onClick={() => { 
                                        if(props.item.Type===207&&props.item.Node===0){
                                            let smokeDevStoList = JSON.parse(localStorage.getItem('smokeDev'));
                                            smokeDevStoList = smokeDevStoList.filter(x=>x!==props.item.Sn);
                                            if(window.CefSharp!==undefined){
                                                window.CefSharp.BindObjectAsync("bound");
                                                window.bound.socketSendAlarm(''+props.item.Sn,1);
                                            }
                                            localStorage.setItem("smokeDev", JSON.stringify(smokeDevStoList));
                                        }else{
                                            setShowHisMore(true); 
                                            startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                            endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                            setShowHis(true);
                                        }
                                    }}
                                    disabled={props.item.ExprieStatus === 3&& state.main.type>101 }
                                >
                                    <div style={{width:80,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                        {(props.item.Type===207&&props.item.Node===0)?'报警灯解警':props.item.MName}
                                    </div>
                                    
                                </Button>
                            </Tooltip>  
                            
                            <div>
                                <Tooltip title={'设置参数'}>
                                    <Button style={{ borderTopLeftRadius:10,borderBottomLeftRadius:10,paddingLeft:8,paddingRight:8}} 
                                    onClick={() => {
                                        (props.item.ExprieStatus !== 3||state.main.type<102) &&setShowSet(true);
                                    }} 
                                    disabled={(props.item.ExprieStatus === 3 && state.main.type>101)||props.item.Node===0}>
                                        参数
                                    </Button>
                                </Tooltip>
                                <Tooltip title={'历史数据'}>
                                    <Button style={{ borderTopRightRadius:10,borderBottomRightRadius:10,paddingLeft:8,paddingRight:8}} 
                                    onClick={() => {
                                        startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                        endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                        setShowHis(true)
                                        props.callback(true)
                                    }} disabled={props.item.ExprieStatus === 3&& state.main.type>101 }
                                    >数据</Button>
                                </Tooltip>
                            </div>
                                
                        </div>
                        
                    </div>
                </div>
                :
                //精简布局
                <div style={{  marginBottom: 8, alignItems: 'center' }}>
                   <Tooltip title={
                    <div>
                        <div >{props.item.Node===0?props.item.MName:props.item.Name}</div>
                        <div onClick={()=>{
                            if(props.item.ExprieStatus === 3 && state.main.type>101) return
                            setShowHisMore(true); 
                            startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                            endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                            setShowHis(true);
                        }}><span>{props.item.Sn}</span> - <span>{props.item.Sensor.Id}</span></div>
                        <div>更新时间:{props.item.Status.LastMsgTime}</div>
                        <div>温度上下限: {`${props.item.Sensor.TempAlarmHigh}~${props.item.Sensor.TempAlarmLow}`+showUnit}</div>
                        <div>{props.item.Humi===1&&`湿度上下限: ${props.item.Sensor.HumiAlarmHigh}~${props.item.Sensor.HumiAlarmLow}%RH`}</div>
                    </div>
                   }>
                    <div style={{border:props.item.ExprieStatus>2 ?'2px solid red': props.item.ExprieStatus>0? '2px solid orange':'', backgroundColor:props.item.Sensor.Hide>1 && '#ccc'}} className='chartLay1'
                    onDoubleClick={()=>{
                        (props.item.ExprieStatus !== 3||state.main.type<102) &&setShowSet(true);
                    }}
                    >                       
                        <div className='smokerAlarm' style={{visibility:(props.item.Type===207&&props.item.Sensor.Smoke)?'visible':'hidden'}}></div>
                        <div style={{ padding:'2px 8px',backgroundColor:props.item.Online&&'#1890ff',borderRadius:5}}>
                            <div style={{ fontSize: '14px',color:'#000', flex: 1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}
                            onClick={() => {
                                if(props.item.ExprieStatus === 3 && state.main.type>101) return
                                startTime===''&& setStartTime(formatDateTimes(startDTime.getTime()));
                                endTime===''&& setEndTime(formatDateTimes(new Date().getTime()));
                                setShowHis(true)
                            }}
                            >
                                <span>{props.item.Node===0?props.item.MName:props.item.Name}</span>
                            </div>
                        </div>

                        {/* 不同类型设备的数据展示 */}
                        {
                            props.item.Node===0?
                            props.item.Type===207?//烟感设备测点0
                            <div >
                                请再其他模式下观看                                                              
                            </div>:
                            (props.item.Sensor.PowerAlarm===0&&props.item.Sensor.DoorAlarm===1)?//其他设备测点0 开关门
                            <div style={{ display: 'flex',fontWeight:'bold',fontSize:12,height:60,justifyContent:'center',alignItems:'center'}}>
                                {
                                    props.item.Online ? 
                                    props.item.Status.Door===1 ?
                                    <span style={{color:'red',}}> 开 门 </span>:
                                    <span > 关 门 </span>:
                                    <span style={{color:'#ccc'}}>--------------</span>
                                }
                            </div>:
                            (props.item.Sensor.PowerAlarm===1&&props.item.Sensor.DoorAlarm===0)?//其他设备测点0 电源状态
                            <div style={{ display: 'flex',fontWeight:'bold',fontSize:12,height:60,justifyContent:'center',alignItems:'center'}}>
                                {
                                    props.item.Online ?
                                    props.item.Status.PowerStatus===1 ?
                                    <span style={{color:'red'}}> 断 电 </span>:
                                    <span > 电 源 正 常 </span>:
                                    <span style={{color:'#ccc'}}>--------------</span>
                                }
                            </div>: //其他设备测点0 电源状态  开关门
                            <div style={{ display: 'flex',flexDirection:'column',fontSize:12,fontWeight:'bold',height:60,justifyContent:'center'}}>
                                <div style={{fontSize:10,fontWeight:'normal',display:'flex',justifyContent:'space-around'}}>
                                    <span>电源状态</span>
                                    <span>门状态</span>
                                </div>
                                {
                                    props.item.Online ?
                                    <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                        <div >
                                            {
                                                props.item.Status.PowerStatus===1 ?
                                                <span style={{color:'red'}}> 断 电 </span>:
                                                <span >  正 常 </span>
                                            }
                                        </div>
                                        <div >
                                            {
                                                props.item.Status.Door===1  ?
                                                <span style={{color:'red'}}> 开 门  </span>:
                                                <span > 关 门 </span>
                                            }
                                        </div>
                                    </div>:
                                    <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                        <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                        <div style={{width:80}}><span style={{color:'#ccc'}}>--------------</span></div>
                                    </div>
                                }
                            </div>://非测点0的数据
                            <div style={{display:'flex',flexDirection:'column', justifyContent:'center'}}> 
                                {/* 温湿度图标 数据 单位 */}
                                <div style={{ display: 'flex', flex:1, marginTop: '10px',  justifyContent: 'space-around',alignItems:'center' }}>
                                    {/* 温度图标 数据 单位 */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img width={24} src={props.item.Online ? tempOnline : tempUnline} />
                                        <div style={{ fontSize: 20,fontWeight:'bold', color: !props.item.Sensor.TempSwitch?'black':props.item.Sensor.TempAlarm? 'red' :props.item.Sensor.TempWarning?'orange':'#000' }}>
                                            {props.item.Sensor.TempValue === 102.3 || props.item.Sensor.TempValue === 102.2 || !props.item.Sensor.TempSwitch ? '--' : props.item.Sensor.TempValue}
                                            
                                            <span style={{fontWeight:'normal',fontSize:14}}>{showUnit}</span>
                                        </div> 
                                        {
                                            props.item.Humi === 1 &&
                                            <div style={{display: 'flex',justifyContent: 'space-around',alignItems:'center'}}>
                                                {/* 湿度图标 数据 单位 */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img width={24} style={{marginTop:4}} src={props.item.Online ? humiOnline : humiUnline} />
                                                    <div style={{ fontSize: 20,fontWeight:'bold', color:!props.item.Sensor.HumiSwitch ?'black':props.item.Sensor.HumiAlarm? 'red' :props.item.Sensor.HumiWarning?'orange' :'#000'}}>
                                                        {props.item.Sensor.HumiValue === 102.3 || props.item.Sensor.HumiValue === 102.2 || !props.item.Sensor.HumiSwitch ?  '--' : props.item.Sensor.HumiValue }
                                                        <span style={{fontWeight:'normal',fontSize:14}}>%RH</span>
                                                    </div>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                                
                            </div>
                        }                     
                    </div>
                    </Tooltip>
                </div>
            }
        </>
    )
}

export default DeviceItem